// config.js

const bizIcon = 'https://ecremodelingllc.us//wp-content/uploads/2023/11/EC-LOGO.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://ecremodelingllc.us/wp-content/uploads/2023/11/36bc068b-2a1d-4bcf-89da-8a404d851dc5.jpg";
const imageTwo = "https://ecremodelingllc.us/wp-content/uploads/2023/11/454285a8-7ea2-4714-8578-74b88a2431cc.jpg";
const imageThree = "https://ecremodelingllc.us/wp-content/uploads/2023/11/a5ff7708-5ebd-4cf4-b927-faf30cbc382d.jpg";
const imageFour = "https://ecremodelingllc.us/wp-content/uploads/2023/11/2a6cf3e4-2947-413c-b071-17e458eb6432.jpg";
const imageFive = "https://ecremodelingllc.us/wp-content/uploads/2023/11/83ddc5cb-2049-4745-89ac-f322fd35dbfe.jpg";

const redirectLink = "https://ecremodelingllc.us/";

export const siteConfig = {
  title: "E&C Remodeling LLC",
  metaDescription: "E&C Remodeling LLC",
  theme: {
    primaryColor: "blue-600",
    secondaryColor: "gray-900",
    accentColor: "text-blue-600"
  },
  logo: {
    src: bizIcon,
    alt: "E&C Remodeling LLC Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "E&C Remodeling LLC",
    headline: "Excellence in Roofing, Demolition, and Siding",
    address: "New York, NY, USA",
    description: `
        Welcome to E&C Remodeling LLC, where excellence meets craftsmanship in the heart of New York. Founded by Ernesto Cifuentes, we specialize in roofing, demolition, and siding for both residential and commercial clients. Proudly serving New York and neighboring communities, we take pride in delivering quality results that turn visions into reality.`,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Excellence in Roofing, Demolition, and Siding",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Fully Insured and Licensed: E&C Remodeling LLC ensures every project is executed with the highest standards, giving you peace of mind.",
    },
    {
        description: 
          "24+ Years of Experience: With over two decades of expertise, we bring professionalism and precision to every remodeling project.",
    },
    {
        description: 
          "Bilingual Services: Our team speaks both English and Spanish, ensuring seamless communication and service for all clients.",
    },
  ],
  services: {
    sectionTitle: "Our Services",
    description: 
    "E&C Remodeling LLC specializes in roofing, demolition, and siding services, catering to both residential and commercial properties in New York. We are committed to delivering exceptional craftsmanship, innovative solutions, and complete customer satisfaction.",
    callouts: [
      {
        name: 'Siding Repair and Installation',
        description: 
          "We provide professional siding repair and installation services, ensuring durability and visual appeal with materials like vinyl, wood, stucco, and fiber cement. Trust us to enhance your property’s protection and aesthetics.",
        imageSrc: imageTwo,
        imageAlt: 'E&C Remodeling LLC team working on siding installation in New York.',
      },
      {
        name: 'Roofing Repair and Replacement',
        description: 
          "Our roofing experts deliver top-quality repairs and replacements to protect your property from the elements. We work with various roofing materials to meet your needs and preferences.",
        imageSrc: imageThree,
        imageAlt: 'Roofing repair and replacement services by E&C Remodeling LLC in New York.',
      },
      {
        name: 'Demolition Services',
        description: 
          "Our demolition services ensure safe and efficient removal of old structures, paving the way for your next project. We handle residential and commercial demolitions with precision and care.",
        imageSrc: imageFour,
        imageAlt: 'E&C Remodeling LLC providing professional demolition services in New York.',
      },
    ],
  },
  about: {
    sectionTitle: "About",
    description:   
      "E&C Remodeling LLC, led by owner Ernesto Cifuentes, is your trusted partner for transformative residential and commercial projects in New York. With a strong commitment to sustainability, we employ eco-friendly practices, high-quality materials, and advanced tools to deliver superior results. Fully licensed and insured, our experienced team ensures precision and excellence in every detail. Contact us today for a free estimate and discover the difference with E&C Remodeling LLC—where your vision becomes reality.",
    image: {
      src: imageFive,
      alt: "E&C Remodeling LLC team delivering high-quality service in New York.",
      width: 1200,
      height: 800
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "New York, USA",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24136.062612564765!2d-74.0059723!3d40.712776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a31600b01d7%3A0xabcdef123456789!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=New%20York,%20USA&ll=40.712776,-74.005974&q=New%20York',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=New+York,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/New+York,+USA/@40.712776,-74.005974,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/New+York,+USA/@40.712776,-74.005974,15z',
      }
    ]
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: "For estimates or inquiries, contact E&C Remodeling LLC today. We’re here to help with all your residential and commercial remodeling needs in New York and surrounding areas.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (516) 304-9982",
    ],
    logo: bizIcon,
    emailRecipient: "fuentemayor7@icloud.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


